import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset, verifyResetCode } from '../api/auth_client';

const ForgotPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      await requestPasswordReset(email);
      setMessage('Un email de réinitialisation a été envoyé');
      setStep(2); // Passer à l'étape de vérification du code
    } catch (error) {
      setMessage('Erreur lors de l\'envoi de l\'email');
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await verifyResetCode(email, code);
      setMessage('Code vérifié avec succès ! Redirection...');
      
      // Récupérer le token retourné par l'API après la vérification du code
      const resetToken = response.data.data.token;
      navigate(`/reset-password/${resetToken}`);
    } catch (error) {
      setMessage('Code incorrect.');
    }
  };
  
  return (
    <div className='login template d-flex justify-content-center align-items-center vh-100 bg-success'>
      <div className='form_container p-5 rounded bg-white'>
        <h3 className='text-center'>Mot de passe oublié</h3>
        
        {step === 1 && (
          <form onSubmit={handleEmailSubmit}>
            <div className='mb-3'>
              <label>Email</label>
              <input 
                type='email' 
                className='form-control' 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
            </div>
            <button type='submit' className='btn btn-success w-100'>Envoyer</button>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleCodeSubmit}>
            <div className='mb-3'>
              <label>Code de vérification</label>
              <input 
                type='text' 
                className='form-control' 
                value={code} 
                onChange={(e) => setCode(e.target.value)} 
                required 
              />
            </div>
            <button type='submit' className='btn btn-success w-100'>Vérifier</button>
          </form>
        )}

        {message && <p className="mt-3 text-center">{message}</p>}
      </div>
    </div>
  );
};

export default ForgotPassword;
