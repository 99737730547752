import React from 'react'
import * as d3 from 'd3'
import * as topojson from 'topojson-client';
import { useState,useEffect,useRef } from 'react';
import '../../../App.css';
import { baseUrl } from '../../../api/auth_constant';
import colors from './colors';
import { useSelector, useDispatch } from 'react-redux';
import {
  setStateReport,
} from '../../../redux/slices/map_venteSlice';
export default function MapChartReports() {
  const dispatch = useDispatch();
  const {
    stateReport,
  } = useSelector((stateReport) => stateReport.mapvente);
    const svgRef = useRef(null);
  const height=850;

  const jsonToData = async (data) => {
    try {
      const currentYear = new Date().getFullYear();
      const responses = await Promise.all([
        d3.json(String(data.countries)),
        d3.json(String(`${baseUrl}reports`), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            annee: 2024,
          }),
        })
      ]);
  
      const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
  
      // Regroupe les données par localisation avant "Benin"
      const groupedData = responses[1].reduce((acc, item) => {
        // Isoler la localisation avant "Benin"
        const locationMatch = item.Unit_last_location.match(/,\s*([^,]+),\s*Benin/i);
        const location = locationMatch ? locationMatch[1].trim() : "Unknown";
        // Enlever les accents et gérer la casse
        const cleanLocation = removeAccents(location);
  
        if (!acc[cleanLocation]) {
          acc[cleanLocation] = {
            Final_mileage_in_engine_hours: 0,
            Final_engine_hours: 0,
          };
        }
  
        // Accumuler les valeurs
        acc[cleanLocation].Final_mileage_in_engine_hours += parseFloat(item.Final_mileage_in_engine_hours || 0);
        acc[cleanLocation].Final_engine_hours += convertTimeToHours(item.Final_engine_hours || 0);
        acc[cleanLocation].count += 1;
  
        return acc;
      }, {});
  
      // Conversion des données groupées en un format approprié
      const locationData = Object.keys(groupedData).reduce((acc, location) => {
        const data = groupedData[location];
        acc[location] = {
          mileage: data.Final_mileage_in_engine_hours || 0,
          engineHours: data.Final_engine_hours || 0,
        };
        return acc;
      }, {});
  
      dispatch(setStateReport({
        country: countriesGeoJSON,
        locationData
      }));
      console.log("data", locationData)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  


  const fetchData = async () => {
    await jsonToData({
      countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
    });
  };

  useEffect(() => {
    fetchData();
  },[]);

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  const convertTimeToHours = (timeStr) => {
    const parts = timeStr.split(':').map(Number);
    return (parts[0] || 0) + ((parts[1] || 0) / 60) + ((parts[2] || 0) / 3600);
  };

useEffect(() => {
  if (stateReport.country && svgRef.current) {
    const svg = d3.select(svgRef.current)
      .attr("viewBox", [0, 0, height / 2])
      .attr("height", height)
      .on("click", reset);

    const tooltip = d3.select("#tooltip").style('pointer-events', 'none').style("visibility", "hidden");

    const datacolor = ["0", "1-100", "101-500", ">500"];
    const xAxisScale = d3.scaleBand()
      .domain(datacolor)
      .range([30, height / 3])
      .padding(0.04);

    const projection = d3.geoProjection((x, y) => [x, Math.log(Math.tan(Math.PI / 4 + y / 2))])
      .scale(5800)
      .translate([-20, 1370]);

    const path = d3.geoPath().projection(projection);

    svg.selectAll("*").remove();
    const g = svg.append("g").attr("fill", "#444");
    g.selectAll("path")
      .data(stateReport.country.features)
      .join('path')
      .attr('fill', function(d) {
        const city = removeAccents(d.properties.name.toLowerCase());
        const data = stateReport.locationData[city] || 0;
        if (!data || typeof data.mileage === 'undefined') {
            return colors[0]; // Couleur par défaut si aucune donnée n'est disponible
          }
        if (data.mileage === 0) {
            return colors[0];
          } else if (data.mileage >= 1 && data.mileage <= 100) {
            return colors[1];
          } else if (data.mileage >= 101 && data.mileage <= 500) {
            return colors[2];
          }
          return colors[3];
        })
      .attr('d', path)
      .attr('title', d => d.properties?.name)
      .attr('stroke', 'gray')
      .attr('cursor', "pointer")
      .on("mouseover", (event, d) => {
        const city = removeAccents(d.properties.name.toLowerCase());
        const data = stateReport.locationData[city]|| 0;
        if (!data || typeof data.mileage === 'undefined' || typeof data.engineHours === 'undefined') {
            tooltip.html(`<em style="text-align:center;font-weight:bold;">${d.properties.name}</em><br/>
              <em style="font-weight:bold;">Pas d'activité</em>`)
              .style('left', `${event.clientX}px`)
              .style('top', `${event.clientY}px`)
              .style("position", "fixed")
              .style("z-index", "1000")
              .style("visibility", "visible");
            return;
          }
            tooltip.html(`<em style="text-align:center;font-weight:bold;">${d.properties.name}</em><br/>
            <em style="font-weight:bold;">Distance parcourue: </em>${data.mileage.toFixed(2)} km<br/>
            <em style="font-weight:bold;">Heure moteur: </em>${data.engineHours.toFixed(2)} h`)
               .style('left',  `${event.clientX}px`)
                      .style('top', `${event.clientY}px`)
                      .style("position", "fixed")
                      .style("z-index", "1000")
                      .style("visibility", "visible");
      })
      .on("mouseout", () => {
        tooltip.html("").style("visibility", "hidden").style("top", 0);
      })
      .on("click", clicked);

    const xAxis = svg.append("g")
      .attr("transform", `translate(0,30)`)
      .call(d3.axisBottom(xAxisScale).tickSizeOuter(0))
      .selectAll("text")
      .style("text-anchor", "middle");

    svg.selectAll(".bar")
      .data(datacolor)
      .enter().append("rect")
      .attr("class", "bar")
      .attr("x", d => xAxisScale(d))
      .attr("width", xAxisScale.bandwidth())
      .attr("y", 10)
      .attr("height", 20)
      .attr("fill", d => {
        switch (d) {
          case "0": return colors[0];
          case "1-100": return colors[1];
          case "101-500": return colors[2];
          case ">500": return colors[3];
        }
      });

    function clicked(event, d) {
      const [[x0, y0], [x1, y1]] = path.bounds(d);
      event.stopPropagation();
    }

    function reset() {}
  }
}, [stateReport]);


  return (
    <>
      <svg width={"100%"} ref={svgRef}/>
      <div id="tooltip"></div>
    </>
  );
}
