import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    prod: 0,
    heurePuissanceTotal: 0,
    totalPuissance : 0,
    conformityData : [],
    heurePuissance : [],
    distanceMarque : [],
    heurePuissanceMarque : [],
    trackMark : 0
};

const productionSlice = createSlice({
  name: 'production',
  initialState,
  reducers: {
    setTotalProductions: (state, action) => {
      state.prod = action.payload;
    },
    setheurePuissanceTotal: (state, action) => {
        state.heurePuissanceTotal = action.payload;
      },
      settotalPuissance: (state, action) => {
        state.totalPuissance = action.payload;
      },
      setConformityData: (state, action) => {
        state.conformityData = action.payload;
      },
      setheurePuissance: (state, action) => {
        state.heurePuissance = action.payload;
      },
      setDistanceMarque: (state, action) => {
        state.distanceMarque = action.payload;
      },
      setheurePuissanceMarque: (state, action) => {
        state.heurePuissanceMarque = action.payload;
      },
      settrackMark: (state, action) => {
        state.trackMark = action.payload;
      },
  },
});

export const {
    setTotalProductions,
    setheurePuissanceTotal,
    settotalPuissance,
    setConformityData,
    setheurePuissance,
    setDistanceMarque,
    setheurePuissanceMarque,
    settrackMark
} = productionSlice.actions;

export default productionSlice.reducer;
