import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalTractoristesCard: 0,
  tractoristesParSexeCard: { masculin: 0, feminin: 0 },
};

const plombierSlice = createSlice({
  name: 'plombier',
  initialState,
  reducers: {
    setTractoristes: (state, action) => {
      state.totalTractoristesCard = action.payload;
    },
    setParSexe: (state, action) => {
        state.tractoristesParSexeCard = action.payload;
      },
  },
});

export const {
    setTractoristes,
    setParSexe,
} = plombierSlice.actions;

export default plombierSlice.reducer;
