import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  state: { country: null,datas:[]},
  states: { country: null, salesByCity: {} },
  stateReport: { country: null,locationData: {} },
stateTracteur : { country: null, tractorsData: [], pannesData: [] }
};

const map_venteSlice = createSlice({
  name: 'mapvente',
  initialState,
  reducers: {
    setState: (state, action) => {
      state.state = action.payload;
    },
    setStates: (state, action) => {
        state.states = action.payload;
      },
      setStateTracteur: (state, action) => {
        state.stateTracteur = action.payload;
      },
      setStateReport: (state, action) => {
        state.stateReport = action.payload;
      },
  },
});

export const {
    setState,
    setStates,
    setStateTracteur,
    setStateReport
} = map_venteSlice.actions;

export default map_venteSlice.reducer;
