// Components/RasaChat.js
import React, { useEffect } from "react";

const RasaChat = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/rasa-webchat/lib/index.js";
    script.async = true;
    script.onload = () => {
      window.WebChat.default(
        {
          initPayload:'/greet',
          customData: { language: "en" },
          socketUrl: "http://194.147.58.205:5005/",
          socketPath: "/socket.io/",
          title: "Bienvenue",
          subtitle: "Comment puis-je vous aider?",
        },
        null
      );
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Nettoyage pour éviter les duplications.
    };
  }, []);

  return <div />;
};

export default RasaChat;
