import React from 'react'
import * as d3 from 'd3'
import * as topojson from 'topojson-client';
import { useState,useEffect,useRef } from 'react';
import '../../../App.css';
import { baseUrl } from '../../../api/auth_constant';
import colorsRend from './colorsRend';
import Autocomplete from '@mui/material/Autocomplete';
import { Container, Grid, Box, Button, Typography, TextField } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export default function MapChartRendement() {
  const [state, setState] = useState({ country: null,datas:[]});
  const [selectedAnnee, setSelectedAnnee] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false); // State pour gérer le mode plein écran
  const [dialogData, setDialogData] = useState(null); // État pour gérer les données du dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false); 
  const svgRef = useRef(null);
  const height=850;
  
  const jsonToData = async (data) => {
    try {
      const responses= await Promise.all([
        d3.json(String(data.countries)),
        d3.json(String(`${baseUrl}/agri`))
      ]);
      const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
      console.log(responses[1])
      setState({ country: countriesGeoJSON,datas:responses[1]});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const anneeOptions = Array.from(new Set(state.datas.map(item => item["ANNEES"])))
  .map(annee => ({ label: annee, value: annee }));

const handleAnneeChange = (event, value) => {
  setSelectedAnnee(value ? value.value : null);
  updateMapData(value ? value.value : null);
};
const handleClose = () => {
  setIsDialogOpen(false);
};
  const fetchData = async () => {
    await jsonToData({
      countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
    });
  };

  useEffect(() => {
    fetchData();
  },[]);

  const updateMapData = (selectedAnnee) => {
    if (state.country && svgRef.current) {
      const svg = d3.select(svgRef.current)
      .attr("viewBox", [0, 0, height/2])
      .attr("height", height)

      const tooltip=d3.select("#tooltip").style('pointer-events','none').style("visibility","hidden")

      const datacolor = [
        "0",
        "1-1000",
        "1001-6000",
        ">6000"
      ];
      const xAxisScale = d3.scaleBand()
      .domain(datacolor)
      .range([30, height/3])
      .padding(0.04);



      const projection = d3.geoProjection(function(x, y) {
        return [x, Math.log(Math.tan(Math.PI / 4 + y / 2))];
      }).scale(5800).translate([-20,1370]);

      const path = d3.geoPath().projection(projection);

      svg.selectAll("*").remove();
      const g = svg.append("g")
      .attr("fill", "#444");
      g.selectAll("path")
      .data(state.country.features)
      .join('path')
      .attr('fill', function(d,i){
        const communeData = state.datas.filter(item => 
          (item["ANNEES"] === selectedAnnee) &&
          item["COMMUNES"].toLowerCase().includes(d.properties.name.toLowerCase()) 
        );
    
        const totalRendMais = d3.sum(communeData.filter(item => item["Culture"] === "Maïs").map(item => item["REND"]));
        const totalRendCoton = d3.sum(communeData.filter(item => item["Culture"] === "Coton").map(item => item["REND"]));
        const totalRendSoja = d3.sum(communeData.filter(item => item["Culture"] === "Soja").map(item => item["REND"]));
        const totalRendRiz = d3.sum(communeData.filter(item => item["Culture"] === "Riz").map(item => item["REND"]));
        const totalRendSorgho = d3.sum(communeData.filter(item => item["Culture"] === "Sorgho").map(item => item["REND"]));
    
        const totalRend = totalRendMais + totalRendCoton + totalRendSoja + totalRendRiz + totalRendSorgho;
    
        if(totalRend === 0) {
          return colorsRend[0];
        } else if(totalRend >= 0 && totalRend <= 1000) {
          return colorsRend[1];
        } else if(totalRend >= 1001 && totalRend <= 6000) {
          return colorsRend[2];
        }
        return colorsRend[3];
      })
      .attr('d', path)
      .attr('title', (d) => d.properties?.name)
      .attr('stroke', 'gray')
      .attr('cursor', "pointer")
      .on("mouseover", (event, d) => {
        const communeData = state.datas.filter(item => 
          (item["ANNEES"] === selectedAnnee) &&
          item["COMMUNES"].toLowerCase().includes(d.properties.name.toLowerCase()) 
                );
    
        const totalRendMais = d3.sum(communeData.filter(item => item["Culture"] === "Maïs").map(item => item["REND"]));
        const totalRendCoton = d3.sum(communeData.filter(item => item["Culture"] === "Coton").map(item => item["REND"]));
        const totalRendSoja = d3.sum(communeData.filter(item => item["Culture"] === "Soja").map(item => item["REND"]));
        const totalRendRiz = d3.sum(communeData.filter(item => item["Culture"] === "Riz").map(item => item["REND"]));
        const totalRendSorgho = d3.sum(communeData.filter(item => item["Culture"] === "Sorgho").map(item => item["REND"]));
    
        tooltip.html(`
          <em style="text-align:center;font-weight:bold;">${d.properties.name}</em><br/>
          <em style="font-weight:bold; text-wrap:nowrap;">Maïs : </em>${totalRendMais.toFixed(2)} kg/ha<br/>
          <em style="font-weight:bold; text-wrap:nowrap;">Coton : </em>${totalRendCoton.toFixed(2)} kg/ha<br/>
          <em style="font-weight:bold; text-wrap:nowrap;">Soja : </em>${totalRendSoja.toFixed(2)} kg/ha<br/>
          <em style="font-weight:bold; text-wrap:nowrap;">Riz : </em>${totalRendRiz.toFixed(2)} kg/ha<br/>
          <em style="font-weight:bold; text-wrap:nowrap;">Sorgho : </em>${totalRendSorgho.toFixed(2)} kg/ha
        `)
        .style('left',  `${event.clientX}px`)
        .style('top', `${event.clientY}px`)
        .style("position", "fixed")
        .style("z-index", "1000")
        .style("visibility", "visible");
      })
      .on("mouseout", (event, i) => {
        tooltip.html("").style("visibility", "hidden").style("top", 0);
      })
      .on("click", clicked);
        const xAxis = svg.append("g")
        .attr("transform", `translate(0,30)`)
        .call(d3.axisBottom(xAxisScale).tickSizeOuter(0))
        .selectAll("text")
        .style("text-anchor", "middle")
      svg.selectAll(".bar")
        .data(datacolor)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", d => xAxisScale(d))
        .attr("width", xAxisScale.bandwidth())
        .attr("y", 10)
        .attr("height", 20)
        .attr("fill", d => {
          switch (d) {
            case "0":
              return colorsRend[0];
            case "1-1000":
              return colorsRend[1];
            case "1001-6000":
              return colorsRend[2];
            case ">6000":
              return colorsRend[3]
          }
        });

        function clicked(event, d) {
          const communeData = state.datas.filter(item => 
            (item["ANNEES"] === selectedAnnee) &&
            item["COMMUNES"].toLowerCase().includes(d.properties.name.toLowerCase()) 
                  );
                  const totalRendMais = d3.sum(communeData.filter(item => item["Culture"] === "Maïs").map(item => item["REND"]));
                  const totalRendCoton = d3.sum(communeData.filter(item => item["Culture"] === "Coton").map(item => item["REND"]));
                  const totalRendSoja = d3.sum(communeData.filter(item => item["Culture"] === "Soja").map(item => item["REND"]));
                  const totalRendRiz = d3.sum(communeData.filter(item => item["Culture"] === "Riz").map(item => item["REND"]));
                  const totalRendSorgho = d3.sum(communeData.filter(item => item["Culture"] === "Sorgho").map(item => item["REND"]));
  
          // Définir les données à afficher dans le dialog
          setDialogData({
            name: d.properties.name,
            totalRendMais: totalRendMais === 0 ? "Aucun" : totalRendMais,
            totalRendCoton: totalRendCoton  === 0 ? 'Aucun' : totalRendCoton,
            totalRendSoja: totalRendSoja === 0 ? 'Aucun' : totalRendSoja,
            totalRendRiz: totalRendRiz  === 0 ? 'Aucun' : totalRendRiz,
            totalRendSorgho: totalRendSorgho  === 0 ? 'Aucun' : totalRendSorgho
          });
          setIsDialogOpen(true); // Ouvrir le dialog
        }
    }
  };

  useEffect(() => {
    if (anneeOptions.length > 0) {
      setSelectedAnnee(anneeOptions[0].value);
      updateMapData(anneeOptions[0].value);
    }
  }, [anneeOptions]);

  const toggleFullscreen = () => {
    const element = document.getElementById("container");
    const isFullscreen = document.fullscreenElement;
    if(isFullscreen){
        document.exitFullscreen();
    }
    else{
        element.requestFullscreen();
    }
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  return (
    <>
       <Box mt={3} mb={3} ml={3} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Autocomplete
          options={anneeOptions}
          getOptionLabel={(option) => option.label}
          value={anneeOptions.find((option) => option.value === selectedAnnee) || null} // Correspond à l'année sélectionnée
          onChange={handleAnneeChange}
          renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
          sx={{ width: 150 }}
        />
      </Box>
      
      <svg width={"100%"} ref={svgRef} id='container'/>
      
      <div id="tooltip"></div>
      <BootstrapDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}   sx={{ 
    '& .MuiPaper-root': { 
      borderRadius: '40px' // Appliquer des bords arrondis au contenu du Dialog
    } 
  }}>
        {dialogData && (
          <>
            <DialogTitle sx={{fontWeight:'bold', color:'green'}}>{dialogData.name}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
          <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 30, // diamètre du cercle
                height: 30, // diamètre du cercle
                borderRadius: '50%', // pour rendre la forme circulaire
                border: '2px solid red', // couleur et taille du bord du cercle
              }}
            >
              <CloseIcon sx={{ color: 'red' }} />
            </Box>
        </IconButton>
            <DialogContent dividers>
              <DialogContentText gutterBottom>
                <strong>mais :</strong> {dialogData.totalRendMais} kg/ha<br />
                <strong>sorgho :</strong> {dialogData.totalRendSorgho} kg/ha<br />
                <strong>riz :</strong> {dialogData.totalRendRiz} kg/ha<br />
                <strong>soja :</strong> {dialogData.totalRendSoja} kg/ha<br />
                <strong>coton :</strong> {dialogData.totalRendCoton} kg/ha<br />
              </DialogContentText>
            </DialogContent>
          </>
        )}
      </BootstrapDialog>
    </>
  );
}
