import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tracteursCount: 0,
  remorquesCount:0,
  autresCount: 0,
  kitsCount: 0,
  inconnu: 0,
  ventePuissance: [],
  venteMarque: [],
};

const commercialSlice = createSlice({
  name: 'commercial',
  initialState,
  reducers: {
    setTracteursCount: (state, action) => {
      state.tracteursCount = action.payload;
    },
    setRemorquesCount: (state, action) => {
        state.remorquesCount = action.payload;
      },
      setAutres: (state, action) => {
        state.autresCount = action.payload;
      },
      setKitsCount: (state, action) => {
        state.kitsCount = action.payload;
      },
      setInconnu: (state, action) => {
        state.inconnu = action.payload;
      },
      setVentePuissance: (state, action) => {
        state.ventePuissance = action.payload;
      },
      setVenteMarque: (state, action) => {
        state.venteMarque = action.payload;
      },
  },
});

export const {
    setTracteursCount,
    setRemorquesCount,
    setAutres,
    setKitsCount,
    setVentePuissance,
    setVenteMarque,
    setInconnu
} = commercialSlice.actions;

export default commercialSlice.reducer;
