import { configureStore } from '@reduxjs/toolkit';
import directeurReducer from './slices/directeurSlice';
import commercialReducer from './slices/commercialSlice';
import mecanicienReducer from './slices/mecanicienSlice';
import formationReducer from './slices/formationSlice';
import irrigationReducer from './slices/irrigationSlice';
import plombierReducer from './slices/plombierSlice';
import electroReducer from './slices/electroSlice';
import productionReducer from './slices/productionSlice'
import mapventeReducer from './slices/map_venteSlice'

const store = configureStore({
  reducer: {
    directeur: directeurReducer,
    commercial: commercialReducer,
    mecanicien: mecanicienReducer,
    formation : formationReducer,
    irrigation : irrigationReducer,
    plombier : plombierReducer,
    electro : electroReducer,
    production : productionReducer,
    mapvente : mapventeReducer
  },
});

export default store;
