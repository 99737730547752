import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  total_tracteur: 0,
  tracteur_passe:0,
  conformite: 0,
  dataPuissance: [],
  trackConf: 0,
  trackConf2:0,
  barData: [],
  articlesParCategorie:0,
  chartData: { labels: [], data: [] },
  generalConformity:0,
  generalOperator : 0,
  conformityData : [],
  averageOperators:{},
  averageOperatorsMarque: {},
  conformityDataMarque: [],
  ProdData : []
};

const directeurSlice = createSlice({
  name: 'directeur',
  initialState,
  reducers: {
    setTotalTracteur: (state, action) => {
      state.total_tracteur = action.payload;
    },
    setTotalTracteurPasse: (state, action) => {
      state.tracteur_passe = action.payload;
    },
    setconforme: (state, action) => {
      state.conformite = action.payload;
    },
    setDataPuissance: (state, action) => {
      state.dataPuissance = action.payload;
    },
    settrackConf: (state, action) => {
      state.trackConf = action.payload;
    },
    settrackConf2: (state, action) => {
      state.trackConf2 = action.payload;
    },
    setArticlesParCategorie: (state, action) => {
      state.articlesParCategorie = action.payload;
    },
    setBarData: (state, action) => {
      state.barData = action.payload;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
    setTotalConformityPercentage: (state, action) => {
      state.generalConformity = action.payload;
    },
    setGeneralAverageOperators: (state, action) => {
      state.generalOperator = action.payload;
    },
    setConformityData: (state, action) => {
      state.conformityData = action.payload;
    },
    setConformityDataMarque: (state, action) => {
      state.conformityDataMarque = action.payload;
    },
    setAverageOperators: (state, action) => {
      state.averageOperators = action.payload;
    },
    setAverageOperatorsMarque: (state, action) => {
      state.averageOperatorsMarque = action.payload;
    },
    setProdData: (state, action) => {
      state.ProdData = action.payload;
    },
  },
});

export const {
  setTotalTracteur,
  setTotalTracteurPasse,
  setconforme,
  setDataPuissance,
  settrackConf,
  setBarData,
  setArticlesParCategorie,
  setChartData,
  setTotalConformityPercentage,
  setGeneralAverageOperators,
  setConformityData,
  setAverageOperators,
  settrackConf2,
  setConformityDataMarque,
  setAverageOperatorsMarque,
  setProdData
} = directeurSlice.actions;

export default directeurSlice.reducer;
