import axios from "axios";
import { registerUrl, loginUrl, forgotPasswordUrl, resetPasswordUrl, verifyCodeUrl} from '../api/auth_constant';


const storeToken = async (token) => {
  try {
    localStorage.setItem('token', token);
  } catch (e) {
    console.error("Error storing token:", e);
  }
};


export const register = async (body, usertype) => {
  try {
    const res = await axios.post(registerUrl, body);
    console.log(res.data, res.status);
      storeToken(res.data.token);
    return res;
  } catch (e) {
    console.error("Error registering:", e);
    throw e;
  }
};


export const login = async (body) => {
  try {
    const res = await axios.post(loginUrl, body);
    console.log(res.data, res.status);

    // Stockez le token après une connexion réussie
    if (res.data.token) {
      storeToken(res.data.token);
    }

    return res;
  } catch (e) {
    console.error("Error logging in:", e);
    throw e;
  }
};

export const verifyResetCode = async (email, code) => {
  return axios.post(verifyCodeUrl, { email, code });
};

export const requestPasswordReset = async (email) => {
  return axios.post(forgotPasswordUrl, { email });
};

export const resetPassword = async (token, newPassword) => {
  const headers = {
    Authorization: `Bearer ${token}`  // Ajoute le token dans l'en-tête Authorization
  };

  try {
    const response = await axios.post(resetPasswordUrl, { newPassword }, { headers });
    return response;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};


