import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    totalPannes: 0,
    nombreClotures: 0,
    nombreNonClotures: 0,
    tempsResolution : 0,
    tracteurPuissance: [],
    chartDataPannesByMark : [],
    total_visites: 0,
    Tracteurs : 0,
    Motoculteurs: 0,
    Autre : 0,
    typeChartData : [],
    total: 0,
    visitesData: [],
};

const mecanicienSlice = createSlice({
  name: 'mecanicien',
  initialState,
  reducers: {
    setTotalPannes: (state, action) => {
      state.totalPannes = action.payload;
    },
    setNombreClotures: (state, action) => {
        state.nombreClotures = action.payload;
      },
      setNombreNonClotures: (state, action) => {
        state.nombreNonClotures = action.payload;
      },
      setTempsResolution: (state, action) => {
        state.tempsResolution = action.payload;
      },
      setTracteurPuissance: (state, action) => {
        state.tracteurPuissance = action.payload;
      },
      setChartDataPannesByMark: (state, action) => {
        state.chartDataPannesByMark = action.payload;
      },
      settotal_visites: (state, action) => {
        state.total_visites = action.payload;
      },
      setTracteurs: (state, action) => {
        state.Tracteurs = action.payload;
      },
      setMotoculteurs: (state, action) => {
        state.Motoculteurs = action.payload;
      },
      setAutre: (state, action) => {
        state.Autre = action.payload;
      },
      setTypeChartData: (state, action) => {
        state.typeChartData = action.payload;
      },
      settot: (state, action) => {
        state.total = action.payload;
      },
      setVisitesData: (state, action) => {
        state.visitesData = action.payload;
      },
  },
});

export const {
    setTotalPannes,
    setNombreClotures,
    setNombreNonClotures,
    setTempsResolution,
    setTracteurPuissance,
    setChartDataPannesByMark,
    settotal_visites,
    setTracteurs,
    setMotoculteurs,
    setAutre,
    setTypeChartData,
    settot,
    setVisitesData
} = mecanicienSlice.actions;

export default mecanicienSlice.reducer;
