import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../api/auth_client';
import { useCookies } from 'react-cookie';
import validateLogin from './Validatelogin';

const Login = ({ onLogin }) => {
  const [form, setForm] = useState({
    mail: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [cookies, setCookie] = useCookies(['user', 'email']);
  const navigate = useNavigate();

  useEffect(() => {
    // Pré-remplir l'email si un cookie existe
    if (cookies.email) {
      setForm((prevForm) => ({
        ...prevForm,
        mail: cookies.email
      }));
    }
  }, [cookies.email]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validation des données avant la soumission
    const validationErrors = validateLogin({
      email: form.mail,
      password: form.password
    });
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    try {
      const response = await login(form);
  
      if (response.error) {
        alert(response.message);
        return;
      }
  
      const userData = {
        token: 'Bearer ' + response.data.login.token,
        usertype: response.data.login.usertype
      };
  
      setCookie('user', userData);
      setCookie('email', form.mail);
      localStorage.setItem('token', userData.token);
      localStorage.setItem('@usertype', response.data.login.usertype);
      onLogin(userData);
  
      switch (response.data.login.usertype) {
        case 'Directeur':
          navigate('/dashboard_director');
          break;
        case 'Commercial':
          navigate('/dashboard_sales');
          break;
        case 'Mecanicien':
          navigate('/dashboard_mechanic');
          break;
        case 'Financier':
          navigate('/dashboard_fin');
          break;
        case 'Informaticien':
          navigate('/dashboard_it');
          break;
        default:
          navigate('/');
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
  
      // Gestion des erreurs spécifiques
      if (error.response && error.response.status === 400) {
        // Si l'erreur est une 401, on suppose que c'est parce que le mot de passe est incorrect
        setErrors({ ...errors, password: "Mot de passe incorrect" });
      } else if (error.response && error.response.status === 400) {
        // Si l'erreur est une 404, on suppose que l'email n'existe pas
        setErrors({ ...errors, email: "Cet email n'existe pas" });
      } else {
        alert('Une erreur est survenue. Veuillez réessayer.');
      }
    }
  };

  return (
    <div className='login template d-flex justify-content-center align-items-center vh-100 bg-success'>
      <div className='form_container p-5 rounded bg-white'>
        <form onSubmit={handleSubmit}>
          <h3 className='text-center'>Connexion</h3>
          <div className='mb-2'>
            <label htmlFor='mail'>Email</label>
            <input type='text' placeholder='Enter Email' name='mail' value={form.mail} onChange={handleChange} 
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                />
                {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
              </div>
          <div className='mb-2'>
            <label htmlFor='password'>Password</label>
            <input type='password' placeholder='Enter Password' 
             name='password' 
             onChange={handleChange} 
             className={`form-control ${errors.password ? 'is-invalid' : ''}`}
             />
             {errors.password && <div className='invalid-feedback'>{errors.password}</div>}
           </div>
          <div className='d-grid'>
            <button type='submit' className='btn btn-success'>Se connecter</button>
          </div>
          <p className='text-end mt-2'>
          <Link to="/forgot-password">Mot de passe oublié?</Link>
          <Link to="/signup" className='ms-2'>S'inscrire?</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
