import { useEffect, useRef, useState } from "react";
import * as topojson from "topojson-client";
import * as d3 from "d3";
import colors from "./colors";
import { baseUrl } from "../../../api/auth_constant";
import { useSelector, useDispatch } from 'react-redux';
import {
  setStates,
} from '../../../redux/slices/map_venteSlice';
export function MapChartArticle() {
  const [svgRef1, svgRef2] = [useRef(null), useRef(null)];
  const height = 850;
  const dispatch = useDispatch();
  const {
    states,
  } = useSelector((states) => states.mapvente);
  const jsonToData = async (data) => {
    try {
      const responses = await Promise.all([
        d3.json(String(data.countries)),
        d3.json(`${baseUrl}commercial/liste_vente`),
        d3.json(`${baseUrl}client`),
      ]);
  
      const countriesGeoJSON = topojson.feature(responses[0], responses[0].objects.collection);
  
      // Association ventes et clients par ville
      const commercials = responses[1];
      const clients = responses[2];
  
      // Calcul des ventes par commune
      const salesByCity = clients.reduce((acc, client) => {
        const clientVentes = commercials.filter(
          (commercial) => commercial.CT_Num === client.CT_Num
        );
  
        if (clientVentes.length > 0) {
          const city = client.CT_Adresse.toLowerCase();
          // Calcul des occurrences des articles vendus
          const articleCounts = clientVentes.reduce((countAcc, vente) => {
            const article = vente.DL_Design;
            countAcc[article] = (countAcc[article] || 0) + 1;
            return countAcc;
          }, {});
  
          // Trouver l'article le plus vendu dans la ville
          const mostSoldArticle = Object.entries(articleCounts).reduce(
            (max, [article, count]) => (count > max.count ? { article, count } : max),
            { article: null, count: 0 }
          );
  
          // Stocker l'article le plus vendu pour la ville
          acc[city] = mostSoldArticle.article;  // Juste l'article ici
        }
  
        return acc;
      }, {});
  
      dispatch(setStates({
        country: countriesGeoJSON,
        salesByCity,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const fetchData = async () => {
    await jsonToData({
      countries: new URL("https://raw.githubusercontent.com/oscarduzer/countries-states-cities-database/master/country.topojson"),
    });
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  const showMap = (svgRef, tooltipId, label, dataByCity) => {
    if (states.country && svgRef.current) {
      const svg = d3.select(svgRef.current)
        .attr("viewBox", `0 0 ${height / 2} ${height}`)
        .attr("height", height);
  
        const tooltip=d3.select("#tooltip1").style('pointer-events','none').style("visibility","hidden")

          
      const projection = d3.geoProjection((x, y) => [x, Math.log(Math.tan(Math.PI / 4 + y / 2))])
        .scale(5800).translate([-20, 1370]);
  
      const path = d3.geoPath().projection(projection);
      const g = svg.append("g").attr("fill", "#444");
  
      // Dessiner la carte avec les données de vente par ville
      g.selectAll("path")
        .data(states.country.features)
        .join("path")
        .attr("d", path)
        .attr("title", (d) => d.properties?.name)
        .attr("stroke", "gray")
        .attr("cursor", "pointer")
        .on("mouseover", (event, d) => {
          const city = d.properties.name.toLowerCase();
          const mostSoldArticle = dataByCity[city] || "0";  // Si l'article le plus vendu n'existe pas 
        
          tooltip.html(`
            <em style="text-align:center;font-weight:bold;">${d.properties.name}</em><br/>
            <em style="font-weight:bold; font-size:12px"; font-family:'New Times Roman'>Article le plus vendu : </em>${mostSoldArticle === "0" ? "Aucun Article" : mostSoldArticle}
          `)
          .style('left',  `${event.clientX}px`)
          .style('top', `${event.clientY}px`)
          .style("position", "fixed")
          .style("z-index", "1000")
          .style("visibility", "visible");
        })        
        .on("mouseout",(event,i)=>{
            tooltip.html("").style("visibility","hidden").style("top",0)
       })

            // Ajouter le texte des articles les plus vendus dans chaque ville
    g.selectAll("text")
    .data(states.country.features)
    .join("text")
    .attr("x", (d) => {
      const [x, y] = projection(d.properties?.centroid || [0, 0]);
      return x;
    })
    .attr("y", (d) => {
      const [x, y] = projection(d.properties?.centroid || [0, 0]);
      return y;
    })
    .attr("dy", -10)  // Décalage vertical pour le texte
    .attr("text-anchor", "middle")
    .attr("font-size", "10px")
    .attr("fill", "#000")
    .text((d) => {
      const city = d.properties.name.toLowerCase();
      const mostSoldArticle = dataByCity[city] || "Aucun article";  // Si l'article le plus vendu n'existe pas
      return mostSoldArticle;
    });
    }
  };
  
  useEffect(() => {
    showMap(svgRef1, "#tooltip1", "Article le plus vendu :", states.salesByCity);
  }, [states]);
  

  return (
    <>
      <svg width={"100%"} ref={svgRef1}/>
      <div id="tooltip1"></div>
    </>
  );
  
}
