import React, { useState, useEffect } from 'react';
import RoleCheck from '../Components/Dashboards/RoleCheck';
import SideBar from './SideBar';
import { useSelector, useDispatch } from 'react-redux';
import {
  setTractoristes,
  setParSexe,
} from '.././redux/slices/formationSlice';
import { AppBar, Toolbar, Box, Container, Grid, Paper, Typography,Autocomplete, TextField } from '@mui/material';
import CustomCard from './CustomCard';
import { formation, formation_tractoristes} from '../api/auth_constant';
import CalendarButton from './CalendarButton';
import ButtonComponent from './ButtonComponent';
import MapChart1 from "../Components/Dashboards/DashBoardSavUtils/MapChart";
import TableComponent from './TableComponent';
import FormationIrrigation from './FormationIrrigation';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import BarChar from './BarChart';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, CartesianGrid } from 'recharts';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import AirIcon from '@mui/icons-material/Air';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import { Tab, Tabs, Nav, Row, Col  } from 'react-bootstrap';
import { useMediaQuery } from '@mui/material';
import PlombierComponent from './PlombierComponent';
import ElectromecanicienComponent from './ElectromecanicienComponent';

const anneeOptions = [
    { label: "2022" },
    { label: "2023" },
    { label: "2024" },

  ];
  const periods = ["Fév-Mars", "Avril-Mai", "Sep-Oct", "Oct-Nov", "Nov-Dec"];
  const colors = ['#191970', '#66bf6a', '#29B6F6', '#AB47BC', '#008080']; // Palette harmonieuse et vibrante
function FormationComponent() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [formPluie, setFormPluie] = useState(0);
  const [formSeche, setFormSeche] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState(null);
  const [card, setCard] = useState(null);
  const [formationData, setFormation] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [totalTractoristes, setTotalTractoristes] = useState(0);
  const [tractoristesParSexe, setTractoristesParSexe] = useState({ masculin: 0, feminin: 0 });
  const [tabValue, setTabValue] = useState('1');
  const [selectedVague, setSelectedVague] = useState(null);
  const [selectedAnnee, setSelectedAnnee] = useState(null);
  const [vagueOptions, setVagueOptions] = useState([]);
  const [chartData, setChartData] = useState({
    labels: periods,
    masculins: new Array(periods.length).fill(0),
    feminin: new Array(periods.length).fill(0),
  });
  const [chartDataForm, setChartDataForm] = useState({ labels: [], data: [] });
  const [PieData, setPieData] = useState([]);
  const [niveauPieData, setniveauPieData] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const dispatch = useDispatch();
  const {
    totalTractoristesCard,
    tractoristesParSexeCard
  } = useSelector((state) => state.formation);
  const countFormationsByMonth = (data) => {
    const months = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    
    // Initialiser le tableau des mois avec des zéros
    const monthlyCounts = new Array(12).fill(0);
  
    // Parcourir les données
    data.forEach(item => {
      // Convertir la date au format Date
      const date = new Date(item.Date);
      
      // Obtenir le mois et l'année de la date
      const month = date.getMonth(); // 0 pour Janvier, 11 pour Décembre
      const year = date.getFullYear();
  
      // Vérifier si l'année correspond à l'année sélectionnée
      if (year === Number(selectedAnnee)) {
        // Incrémenter le compteur du mois correspondant
        monthlyCounts[month]++;
      }
    });
  
    return { labels: months, data: monthlyCounts };
  };

  useEffect(() => {
    fetchData(formation);
    fetchTractoristesData(formation_tractoristes);
    fetchTractoristesCard(formation_tractoristes)
  }, [selectedVague, selectedAnnee]);

  const fetchData = async (formation) => {
    try {
      const response = await fetch(formation);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
      }

      const jsonData = await response.json();
      setData(jsonData);
      const { labels, data: monthlyData } = countFormationsByMonth(data);
      setChartDataForm({
        labels,
        data: monthlyData,
      });
      calculateStats(jsonData);
      const sortedData = jsonData.sort((a, b) => new Date(b.Date) - new Date(a.Date));
      setFormation(sortedData);
    } catch (error) {
      console.error('Erreur:', error);
    }
  };
  
  
  const filterByPeriod = (data) => {
  const periods = ["Janv-Fév","Fév-Mars", "Mars-Avril", "Avril-Mai", "Mai-Juin","Juin-Juil","Juil-Août","Août-Sep","Sep-Oct","Oct-Nov","Nov-Dec"];
    const periodCounts = periods.reduce((acc, period) => {
      acc[period] = { masculin: 0, feminin: 0 };
      return acc;
    }, {});
  
    data.forEach(item => {
  
      const period = item["Période de la formation"];
  
      if (period && periods.includes(period)) {
        if (item.Sexe === 'M') periodCounts[period].masculin++;
        else if (item.Sexe === 'F') periodCounts[period].feminin++;
      }
    });
    
    return periodCounts;
  };
  const filterByAge = (data) => {
    const ageGroups = {
      '0-25': 0,
      '25-50': 0,
      '>50': 0,
      'N/A': 0
    };
  
    data.forEach(item => {
      const age = item.Age; 
      if (age === undefined || age === "") {
        ageGroups['N/A']++;
      } else {
        if (age <= 25) ageGroups['0-25']++;
        else if (age <= 50) ageGroups['25-50']++;
        else if (age > 50) ageGroups['>50']++;
      }
    });    
  
    return ageGroups;
  };
  
  
  const fetchTractoristesData = async () => {
    try {
      const response = await fetch(formation_tractoristes, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Vague: selectedVague,
            Année: selectedAnnee,
        }),
    });
    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
    }
      const jsonData = await response.json();
      
      const uniqueVagues = [...new Set(jsonData.map(item => item['Vague']))].filter(Boolean);
      setVagueOptions(uniqueVagues.map(label => ({ label })));
      // Calculer les statistiques
      const totalTractoristes = jsonData.length;
      const periode = jsonData.filter(item => item['Période de la formation']);      
      const tractoristesParSexe = jsonData.reduce((acc, curr) => {
        if (curr.Sexe === 'M') {
          acc.masculin++;
        } else if (curr.Sexe === 'F') {
          acc.feminin++;
        }
        return acc;
      }, { masculin: 0, feminin: 0 });
       const periodCounts = filterByPeriod(jsonData);
      // Mettre à jour les états
      setTotalTractoristes(totalTractoristes);
      setTractoristesParSexe(tractoristesParSexe);
      const chartData = {
        labels: periods,
        masculins: periods.map(period => periodCounts[period]?.masculin || 0),
        feminin: periods.map(period => periodCounts[period]?.feminin || 0),
      };
      setChartData(chartData);

      const siteFormationData = jsonData.reduce((acc, curr) => {
        const site = curr["Site de formation "];
        if (!acc[site]) {
          acc[site] = { total: 0, masculin: 0, feminin: 0 };
        }
        acc[site].total++;
        return acc;
      }, {});
        // Conversion des données en format pour le pie chart
        const pieChartData = Object.keys(siteFormationData).map(site => ({
          name: site,
          total: siteFormationData[site].total,
        }));
        setPieData(pieChartData);
        const niveauFormationData = jsonData.reduce((acc, curr) => {
          const niveau = curr["niveau_etude_groupe"];
        if (!acc[niveau]) {
          acc[niveau] = { total: 0, masculin: 0, feminin: 0 };
        }
        acc[niveau].total++;
        return acc;
      }, {});
          // Conversion des données en format pour le pie chart
          const niveauChartData = Object.keys(niveauFormationData).map(niveau => ({
            name: niveau,
            total: niveauFormationData[niveau].total,
          }));
          const filteredData = niveauChartData.filter(data => data.name.trim() !== "" && !["/", " "].includes(data.name));
          setniveauPieData(filteredData);
          const ageStats = filterByAge(jsonData);
          const ageDataFormatted = [
            { label: '0-25', value: ageStats['0-25'] || 0 },
            { label: '25-50', value: ageStats['25-50'] || 0 },
            { label: '>50', value: ageStats['>50'] || 0 },
            { label: 'Non renseignés', value: ageStats['N/A'] || 0 },
          ];
          setAgeData(ageDataFormatted);
        } catch (error) {
      console.error('Erreur:', error);
    }
  };
  
  const fetchTractoristesCard= async () => {
    try {
      const response = await fetch(formation_tractoristes, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            Vague: "",
            Année: "",
        }),
    });
    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données');
    }
      const jsonData = await response.json();
      setCard(jsonData);   
      const totalTractoristesCard = jsonData.length;
      const tractoristesParSexeCard = jsonData.reduce((acc, curr) => {
        if (curr.Sexe === 'M') {
          acc.masculin++;
        } else if (curr.Sexe === 'F') {
          acc.feminin++;
        }
        return acc;
      }, { masculin: 0, feminin: 0 });
  
      // Mettre à jour les états
      dispatch(setTractoristes(totalTractoristesCard));
      dispatch(setParSexe(tractoristesParSexeCard)); 
    } catch (error) {
      console.error('Erreur:', error);
    }
  };
  const calculateStats = (data) => {
    const totalFormations = countTotalFormations(data);
    const formationsParSaison = countFormationsBySeason(data);
    
    setTotal(totalFormations);
    setFormPluie(formationsParSaison.pluie);
    setFormSeche(formationsParSaison.seche);
  };

  const countTotalFormations = (formations) => formations.length;

  const countFormationsBySeason = (formations) => {
    const saisons = { pluie: 0, seche: 0 };
    formations.forEach(formation => {
      const mois = new Date(formation.Date).getMonth() + 1;
      if (mois >= 5 && mois <= 10) {
        saisons.pluie++;
      } else {
        saisons.seche++;
      }
    });
    return saisons;
  };
  const columns = [
    { id: 'Client', label: 'Client', align: 'right' },
    { id: 'Bref description sur la formation', label: 'Brève Description', align: 'right', width: 250 },
    { id: 'Commune', label: 'Commune', align: 'right' },
    { id: 'Date', label: 'Date', align: 'right' },
    { id: 'Genre de visite', label: 'Genre de Visite', align: 'right' },
    { id: 'Intervenant', label: 'Intervenant', align: 'right' },
    { id: 'Numéro du chassis', label: 'Numéro du Chassis', align: 'right' },
    { id: 'Numéro du moteur', label: 'Numéro du Moteur', align: 'right' },
    { id: 'Objet de la formation', label: 'Objet de la Formation', align: 'right' },
    { id: 'Remarque supplémentaire de l\'agro-Équipementier pour la SoNaMA', label: 'Remarque de l\'Agro-Équipementier', align: 'right' },
    { id: 'Remarque supplémentaire du client pour la SoNaMA', label: 'Remarque du Client', align: 'right' },
    { id: 'Types d\'engins visité', label: 'Types d\'Engins Visité', align: 'right' },
  ];

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
};

const handleDrawerClose = () => {
    setSidebarOpen(false);
};

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleVagueChange = (event, value) => {
    setSelectedVague(value?.label || null);
  };
  
  const handleAnneeChange = (event, value) => {
    setSelectedAnnee(value?.label || null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const isXs = useMediaQuery('(max-width:600px)');
  const isXl = useMediaQuery('(min-width:1200px)');

    return (
    <Box sx={{ display: 'flex' }}>
    <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
    <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 2 } }}>
      <Toolbar />
        <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {[ 'Tractoriste', 'Electricien','Plombier','Electromécanicien','Cartes'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
          <TabPanel value="1">
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={4}>
      <CustomCard title="Personnes formées" value={totalTractoristesCard} color="#ffff" icon={<AddCircleOutlineIcon />} 
     />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <CustomCard title="Hommes" value={tractoristesParSexeCard.masculin} color="#ffff" icon={<ManIcon />}/>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <CustomCard title="Femmes" value={tractoristesParSexeCard.feminin} color="#ffff" icon={<WomanIcon />}/>
    </Grid>
  </Grid>

  <Grid container display="flex" alignItems="center" spacing={2} mt={3}>
    <Grid item xs={12} md={8}>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Autocomplete
          options={vagueOptions}
          onChange={handleVagueChange}
          renderInput={(params) => <TextField {...params} label="Vague" color="success" size="small" />}
          sx={{ width: { xs: 120, sm: 150 }, marginRight: 2 }}
        />
        <Autocomplete
          options={anneeOptions}
          onChange={handleAnneeChange}
          renderInput={(params) => <TextField {...params} label="Année" color="success" size="small" />}
          sx={{ width: { xs: 120, sm: 150 }, marginLeft: 2 }}
        />
      </Box>
    </Grid>
  </Grid>
  <Typography 
      variant="h6" 
      sx={{ 
        fontWeight: 'bold', 
        fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' }, 
        textAlign: 'center', 
        mb: { xs: 2, sm: 3 },
        mt: { xs: 2, sm: 2, md:2 },
        color: '#333', // Couleur du texte plus prononcée
      }}
    >
      Répartition des formations par genre
    </Typography>
  <Box   display="flex"
  justifyContent="center"
  alignItems="center"
 mt={2}>
    <Paper elevation={3} sx={{ padding: 2, borderRadius: 4, 
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' ,
      width: { xs: '100%', sm: '90%', md: 'auto' },
      maxWidth: {md: isXl ? 1050: 1000 },
    }} 
      >
    <BarChar data={chartData} />
    </Paper>
</Box>

<Grid container spacing={4} mt={3}>
  <Grid item xs={12} md={6}>
    <Typography 
      variant="h6" 
      sx={{ 
        fontWeight: 'bold', 
        fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' }, 
        textAlign: 'center', 
        mb: { xs: 2, sm: 3 },
        color: '#333', // Couleur du texte plus prononcée
      }}
    >
      Formations par site
    </Typography>
    <Paper 
      elevation={3} 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',  
        width: { xs: '100%', sm: '90%', md: 'auto' }, 
        maxWidth: { xs: 340, sm: 540, md: 640 }, 
        height: { xs: 340, sm: 440, md: 440 }, 
        padding: 2, // Ajout de padding pour l'aération
        borderRadius: 3, // Arrondi du Paper
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Ombre douce
      }}
    >
    <PieChart width={420} height={420}>
  <Pie 
    data={PieData} 
    dataKey="total" 
    nameKey="name" 
    cx="50%" 
    cy="50%" 
    outerRadius={150} 
    innerRadius={60} // Ajout d'un trou intérieur pour l'effet donut
    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`} // Label personnalisé
    isAnimationActive={true} // Animation activée
    
  >
    {PieData.map((entry, index) => (
      <Cell 
        key={`cell-${index}`} 
        fill={colors[index % colors.length]} // Couleurs dynamiques pour chaque cellule
        stroke="#fff" // Contour blanc pour plus de contraste
        strokeWidth={2} // Épaisseur du contour
      />
    ))}
  </Pie>
  <Tooltip 
    formatter={(value) => `${value} personnes formées`} // Tooltip personnalisé
    cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} // Ajout d'une ombre douce sur hover
  />
  <Legend layout="horizontal" align="center" verticalAlign="bottom" /> 
</PieChart>
    </Paper>
  </Grid>
  <Grid item xs={12} md={6}>
  <Typography 
    variant="h6" 
    sx={{ 
      fontWeight: 'bold', 
      fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.7rem' }, 
      textAlign: 'center', 
      mb: { xs: 2, sm: 3 },
      color: '#333', // Couleur du texte plus prononcée
    }}
  >
    Formations par niveau d'étude
  </Typography>
  <Paper 
    elevation={3} 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',  
      width: { xs: '100%', sm: '90%', md: 'auto' }, 
      maxWidth: { xs: 340, sm: 540, md: 640 }, 
      height: { xs: 340, sm: 440, md: 440 }, 
      padding: 2, // Ajout de padding pour l'aération
      borderRadius: 3, // Arrondi du Paper
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Ombre douce
    }}
  >
    <PieChart width={420} height={420}>
      <Pie 
        data={niveauPieData} 
        dataKey="total" 
        nameKey="name" 
        cx="50%" 
        cy="50%" 
        outerRadius={130} 
        innerRadius={60} // Effet donut pour un look plus moderne
        fill="#4bc0c0" 
        label={({ name, percent }) => ` ${(percent * 100).toFixed(0)}%`} // Label personnalisé
        isAnimationActive={true} // Animation activée
      >
        {niveauPieData.map((entry, index) => (
          <Cell 
            key={`cell-${index}`} 
            fill={colors[index % colors.length]}
            stroke="#fff" // Contour blanc pour plus de contraste
            strokeWidth={2} // Épaisseur du contour
          />
        ))}
      </Pie>
      <Tooltip 
        formatter={(value) => `${value} personnes`} // Tooltip personnalisé
        cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} // Ombre douce sur hover
      />
      {!isXs && <Legend layout="horizontal" align="center" verticalAlign="bottom" />} 
    </PieChart>
  </Paper>
</Grid>
</Grid>
<Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' }, textAlign: 'center', mb: { xs: 2, sm: 3 }, mt: { xs: 2, sm: 3 ,md: 3}}}>
        Formations par âge
      </Typography>
<Box display="flex"
    justifyContent="center"
    alignItems="center" mt={3}>
      <Paper 
        elevation={3} 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',  
          width: { xs: '100%', sm: '90%', md: '100%' }, 
          maxWidth: { xs: 360, sm: 620, md: 500 }, 
          height: { xs: 320, sm: 420, md: 420 }, 
          padding: 2,
          borderRadius: 3,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={ageData} margin={{ top: 20, right: 20, bottom: 20, left: 0 }}>
            <XAxis dataKey="label" tick={{ fontSize: 14, fontWeight: 'bold' }} />
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis tick={{ fontSize: 14, fontWeight: 'bold' }} />
            <Tooltip formatter={(value) => `${value} participants`} />
            <Bar dataKey="value" name="Nombre de participants" fill="#3A8EBA" barSize={40} radius={[10, 10, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </Box>

      </TabPanel>

          <TabPanel value="2">
         <FormationIrrigation/>
          </TabPanel>
          <TabPanel value="3">
         <PlombierComponent/>
          </TabPanel>
          <TabPanel value="4">
         <ElectromecanicienComponent/>
          </TabPanel>
          <TabPanel value="5">
          <Grid item>
            <Typography sx={{fontWeight:'bold', fontSize:'1.5rem'}}>Formation par ville</Typography>
                  <Paper elevation={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: 'auto',
                    width: 430,
                    position: 'relative'
                  }}>
                    <MapChart1 />
                  </Paper>
                </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default RoleCheck(['Mecanicien'])(FormationComponent);
