import React, { useState, useEffect } from 'react';
import RoleCheck from './RoleCheck';
import SideBar from '../SideBar';
import { useSelector, useDispatch } from 'react-redux';
import {
  setTotalPannes,
  setNombreClotures,
  setNombreNonClotures,
  setTempsResolution,
  setTracteurPuissance,
  setChartDataPannesByMark,
  settotal_visites,
  setTracteurs,
  setMotoculteurs,
  setAutre,
  setTypeChartData,
  settot,
  setVisitesData
} from '../../redux/slices/mecanicienSlice';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { BarChart, Bar, Cell, ResponsiveContainer, LabelList } from 'recharts';
import { AppBar, Toolbar, Typography, Grid, Box, Select, MenuItem, FormControl, TextField, Paper  } from '@mui/material';
import { Tab, Tabs, Nav, Row, Col, Container  } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importer Bootstrap CSS
import CustomCard from '../CustomCard';
import CalendarButton from '../CalendarButton';
import ButtonComponent from '../ButtonComponent';
import TableComponent from '../TableComponent';
import MapChart2 from "./DashBoardMecanicienUtils/MapChart";
import TourIcon from '@mui/icons-material/Tour';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import RepartitionIcon from '@mui/icons-material/Repartition';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { panne, stastiquePanne, tractor , stastiqueVisite, visit, panne_par_annee} from '../../api/auth_constant';
import BuildIcon from '@mui/icons-material/Build';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import { useMediaQuery } from '@mui/material';

const vagueOptions = [
  { label: "" },  
  { label: "Marques"},
  { label: "Puissance motrice"},
];

const categories = {
  'TRACTEURS': [
    'PSF0024', 'PSF0091', 'PSF0083', 'PSF0087', 'PSF0050', 'PSF0052', 'PSF0051'
  ],
  'AUTRES': [
    'PSF0022', 'PSF0012', 'PSF0011', 'PSF0010', 'PSF0092', 'PSF0085', 'PSF0102',
    'PSF0089', 'PSF0055', 'PSF0139', 'PSF0058', 'PSF0137', 'PSF0103', 'PSF0104',
    'PSF0086', 'PSF0001', 'PSF0015', 'PSF0108', 'PSF0107', 'PSF0106', 'PSF0105',
    'PSF0101', 'PSF0035', 'PSF0033', 'PSF0034', 'PSF0059', 'PSF0130', 'PSF0090',
    'PSF0041', 'PSF0038', 'PSF0039', 'PSF0084', 'PSF0040', 'PSF0088',
    'PSF0079'
  ]
};

function DashboardMecanicien() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedVague, setSelectedVague] = useState(vagueOptions[0].label);
  const [pannesData, setPannesData] = useState([]);
  const [tabValue, setTabValue] = useState('1');
  const [selectedAnnee, setSelectedAnnee] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [],
    data: [],
  });
  const [totPanne, setTracteurPannes] = useState(0);
  const [totPanneMarque, setTracteurPannesMarque] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const {
    totalPannes,
    nombreClotures,
    nombreNonClotures,
    tempsResolution,
    tracteurPuissance,
    chartDataPannesByMark,
    total_visites,
    Motoculteurs,
    Tracteurs,
    Autre,
    typeChartData,
    total,
    visitesData
  } = useSelector((state) => state.mecanicien);
  useEffect(() => {
    fetchData();
    fetchTypeChartData();
    fetchData6(panne);
  }, []);
  const [anneeOptions, setAnneeOptions] = useState([
    { label: "2023" },
    { label: "2024" },
  ]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    if (!anneeOptions.some(option => option.label === String(currentYear))) {
      setAnneeOptions(prevOptions => [...prevOptions, { label: String(currentYear) }]);
    }
  }, [anneeOptions]);

useEffect(() => {
  fetchData1(stastiqueVisite);
  fetchVisitesData(visit);
}, []);

useEffect(() => {
  if (selectedAnnee) {
    fetchData2(selectedAnnee);
  }
}, [selectedAnnee]);
const fetchData1 = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    dispatch(settotal_visites(data["total_visites"]));
    dispatch(setAutre(data["total_non_repertories"]));
    dispatch(setMotoculteurs(data["total_motoculteurs"]));
    dispatch(setTracteurs(data["total_tracteurs"]));

    const formattedData = Object.entries(data.visites_par_marque).map(([label, visite], index) => ({
      id: index,
      label,  // Category (marque)
      visite,  // Number of visits
    } 
  ));
  formattedData.sort((a, b) => b.visite - a.visite);
    dispatch(setTypeChartData(formattedData));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const fetchVisitesData = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    // Assurez-vous que les dates sont bien formatées ici, si nécessaire
    const sortedData = data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
    dispatch(setVisitesData(sortedData));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
const regrouperTracteursParNomAppareil = (data) => {
  return data.reduce(
    (acc, panneItem) => {
      const designationTracteur = panneItem['Puissance']; 
      if (!designationTracteur) return acc;

      if (!acc.tracteurs[designationTracteur]) {
        acc.tracteurs[designationTracteur] = {
          name: designationTracteur,
          Pannes: 0,
        };
      }
      
      acc.tracteurs[designationTracteur].Pannes += 1;
      acc.totPannes += 1; // Incrémentation du total des pannes

      return acc;
    },
    { tracteurs: {}, totPannes: 0 } // Initialisation avec un total de pannes à 0
  );
};
const regrouperTracteursParNomAppareilMarque = (data) => {
  return data.reduce(
    (acc, panneItem) => {
      const designationTracteur = panneItem['Marque'];
      if (!designationTracteur) {
        return acc;
      }
      if (!acc.tracteursMarque[designationTracteur]) {
        acc.tracteursMarque[designationTracteur] = {
          name: designationTracteur,
          Pannes: 0,
        };
      }
      acc.tracteursMarque[designationTracteur].Pannes += 1;
      acc.totPannesMarque += 1; // Incrémentation du total des pannes

      return acc;
    },
    { tracteursMarque: {}, totPannesMarque: 0 }
  );
};
  const fetchData = async () => {
    try {
      const response = await fetch(panne);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      // Total de pannes
      const total = data.length;
      dispatch(setTotalPannes(total));
      
      // Temps moyen de résolution
      const totalTempsResolution = data.reduce((acc, panne) => {
        if (panne.start && panne.end) {
          const start = new Date(panne.start);
          const end = new Date(panne.end);
          return acc + (end - start);
        }
        return acc;
      }, 0);
      const averageTempsResolution = totalTempsResolution / total;
      const hours = Math.floor(averageTempsResolution / (1000 * 60 * 60));
      const minutes = Math.floor((averageTempsResolution % (1000 * 60 * 60)) / (1000 * 60));
      dispatch(setTempsResolution(hours));

      // Pannes résolues et non résolues
      const clotures = data.filter(panne => panne['Dossier clôturé'].toLowerCase() === 'oui').length;
      const nonClotures = data.filter(panne => panne['Dossier clôturé'].toLowerCase() === 'non' || panne['Dossier clôturé'] === '').length;
      dispatch(setNombreClotures(clotures));
      dispatch(setNombreNonClotures(nonClotures));

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchData2 = async () => {
    try {
      const response = await fetch(panne_par_annee, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          annee: Number(selectedAnnee), 
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
      if (data && data.data && data.data.pannes_par_mois) {
        // Noms des mois pour affichage
        const moisLabels = [
          'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
          'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre',
        ];
  
        const moisDonnees = moisLabels.map((mois, index) => ({
          month: mois,
          Panne: 0, // Valeur par défaut
        }));
      
        // Mise à jour des mois avec les données disponibles
        data.data.pannes_par_mois.forEach((item) => {
          const moisIndex = item.mois - 1; // Indice du mois (0 pour janvier, 11 pour décembre)
          moisDonnees[moisIndex].Panne = item.nombre_de_pannes || 0;
        });
      
        // Mise à jour des données du graphique
        setChartData(moisDonnees);
      } else {
        console.error('Données inattendues ou vides');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error);
    }
  };
  const fetchData6 = async (urlPanne) => {
    try {
      const response = await fetch(urlPanne);
      if (!response.ok) throw new Error('Failed to fetch data from panne');
      const dataPanne = await response.json();
  
      // Regrouper les tracteurs par nom d'appareil
      const { tracteurs: tracteursParNomAppareil, totPannes } = regrouperTracteursParNomAppareil(dataPanne);  
      const tracteursParNomAppareilData = Object.keys(tracteursParNomAppareil)
        .map(nomAppareil => ({
          name: nomAppareil, 
          Pannes: tracteursParNomAppareil[nomAppareil].Pannes,
          label: `${tracteursParNomAppareil[nomAppareil].Pannes}`, 
        }))
        .sort((a, b) => b.Pannes - a.Pannes);
  
      // Regrouper les tracteurs par marque d'appareil
      const { tracteursMarque: tracteursParNomAppareilMarque, totPannesMarque } = regrouperTracteursParNomAppareilMarque(dataPanne);  
      const tracteursParNomAppareilDataMarque = Object.keys(tracteursParNomAppareilMarque)
        .map(nomAppareil => ({
          name: nomAppareil, 
          Pannes: tracteursParNomAppareilMarque[nomAppareil].Pannes,
          label: `${tracteursParNomAppareilMarque[nomAppareil].Pannes}`, 
        }))
        .sort((a, b) => b.Pannes - a.Pannes);
  
      dispatch(setTracteurPuissance(tracteursParNomAppareilData));
      setTracteurPannes(totPannes);
      setTracteurPannesMarque(totPannesMarque);
      dispatch(setChartDataPannesByMark(tracteursParNomAppareilDataMarque));
    } catch (error) {
      console.error('Error fetching and processing data:', error);
    }
  };

  const fetchTypeChartData = async () => {
    try {
      const response = await fetch(stastiquePanne);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      const combinedData = data.pannes_par_marque;
  
      // Calculer le total des pannes
      const totalPannes = Object.values(combinedData).reduce((total, value) => total + value, 0);
  dispatch(settot(totalPannes));
      // Formater les données pour le graphique
      const formattedData = Object.entries(combinedData).map(([label, value], index) => ({
        id: index,
        label: label,
        value: value,
      }));
  
      setTypeChartData(formattedData);
  
      // Afficher le total (ou le stocker dans un état si besoin)
      console.log("Total des pannes:", totalPannes);
      
    } catch (error) {
      console.error('Error fetching type chart data:', error);
    }
  };
  
  const handleVagueChange = (event, newValue) => {
    setSelectedVague(newValue ? newValue.label : vagueOptions[0].label);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAnneeChange = (event, value) => {
    setSelectedAnnee(value?.label || null);
  };

  const columns = [
    { id: 'Client', label: 'Client', align: 'right', flex: 1 },
    { id: 'Date', label: 'Date', align: 'right', flex: 1 },
    { id: 'Cause', label: 'Cause', align: 'left', flex: 2 }, 
    { id: 'Description', label: 'Description', align: 'right', flex: 3 },
    { id: 'Dossier clôturé', label: 'Dossier clôturé', align: 'right', flex: 1 },
    { id: 'Intervenant', label: 'Intervenant', align: 'right', flex: 1 },
    { id: 'Machine en panne', label: 'Machine en panne', align: 'right', flex: 1 },
    { id: 'Numéro de châssis', label: 'Numéro de châssis', align: 'right', flex: 1 },
    { id: 'Genre de visite', label: 'Genre de visite', align: 'right', flex: 1 },
  ];
  const isXs = useMediaQuery('(max-width:600px)');
  const isXl = useMediaQuery('(min-width:1200px)');

  return (
    <Box sx={{ display: 'flex' }}>
    <SideBar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
    <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, sm: 2, md: 3 } }}>
      <Toolbar />
        <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 3, borderColor: 'divider' }}>
              <Nav variant="tabs" className="flex-column flex-sm-row">
                {['Pannes et maintenance', 'Suivi équipement', 'Cartes'].map((label, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={`${index + 1}`}
                      active={tabValue === `${index + 1}`}
                      onClick={() => handleTabChange(null, `${index + 1}`)}
                      style={{
                        color: tabValue === `${index + 1}` ? '#F6CA11' : '#02834A',
                        fontSize: '1.2rem',
                        textTransform: 'none',
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Box>
          <TabPanel value="1">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard title="Signalées" value={`${totalPannes}`} color="#ffff" icon={<BuildIcon />}/>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard title="Résolues" value={`${nombreClotures}`} color="#ffff" icon={<DoneAllIcon />}/>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard title="Non résolues" value={`${nombreNonClotures}`} color="#ffff" icon={<BusAlertIcon />}/>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <CustomCard title="Temps résolution"     value={
                        <>
                    {tempsResolution} 
                 <span style={{ fontSize: '1.2rem' }}> heures</span>
                     </>
                 }  color="#ffff" icon={<AlarmOnIcon />}/>
              </Grid>
              </Grid>
              <Box mt={3}>
              <Autocomplete
            options={vagueOptions}
            onChange={handleVagueChange}
            renderInput={(params) => <TextField {...params} label="Filtre" color='success' size='small' />}
            sx={{ width: 200, ml: 2 }} 
          />
          </Box>
          {(selectedVague === "") &&(
    <Grid item xs={12} md={6} lg={12} mt={3}>
          <Paper
            elevation={3}
            sx={{
              width: { xs: '100%', sm: '90%', md: 'auto' },
              maxWidth: { xs: 350, sm: 500, md: isXl ? '100%':1000 },
              height: { xs: 380, sm: 400, md: 400 },
              padding: 2,
              borderRadius: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >     
              <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Pannes par puissance</span>
                <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${totPanne}`})</span>
              </div>
        {tracteurPuissance && tracteurPuissance.length > 0 ? (
           <ResponsiveContainer width="100%" height={300}>
           <BarChart
             data={tracteurPuissance} 
             margin={isXs ? {} : { top: 10, right: 30, left: 20, bottom: 5 }}
           >
             <XAxis 
               type="category" 
               dataKey="name" 
               axisLine={false} 
               tickMargin={isXs ? 2 : 15} 
               tickLine={false} 
               interval={0} 
               tick={{ fontSize: isXs ? 15 : 18, fontFamily: 'Times New Roman', fontWeight: 'bold' }} 
             />
             <YAxis 
               type="number" 
               axisLine={false} 
               tick={{ fontSize: 14 }} 
             />
             <Tooltip />
             <Bar dataKey="Pannes" fill="#F6CA11" radius={[5, 5, 0, 0]} barSize={40}>
               <LabelList 
                 dataKey="Pannes" 
                 position="insideEnd" 
                 fill="#fff" 
                 fontSize={14} 
                 offset={10} 
               />
             </Bar>
           </BarChart>
         </ResponsiveContainer>
        ) : (
          <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
        )}
      </Paper> 
      <Box display="flex"
  justifyContent="center"
  alignItems="center" mt={2}>
                <Paper 
                  elevation={3}
                  sx={{
                    width: { xs: '100%', sm: '90%', md: 'auto' },
                    maxWidth: { xs: 350, sm: 500, md: isXl ? 800:600 },
                    height: { xs: 380, sm: 400, md: 400 },
                    padding: 2,
                    borderRadius: 3,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >          
    <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Pannes par marque</span>
                <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${totPanneMarque}`})</span>
              </div>
          <ResponsiveContainer width="100%" height={250}>
      <BarChart
        data={chartDataPannesByMark}
        margin={isXs ? {} : { top: 15, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis 
          type="category" 
          dataKey="name" 
          axisLine={false} 
          tickMargin={10} 
          tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }} 
        />
        <YAxis 
          type="number" 
          axisLine={false} 
          tick={{ fontSize: 14 }} 
        />
        <Tooltip />
        <Bar 
          dataKey="Pannes" 
          fill="#F6CA11" 
          radius={[5, 5, 0, 0]} 
          barSize={40} 
        >
          <LabelList 
            dataKey="Pannes" 
            position="indideEnd" 
            fill="#fff" 
            fontSize={14} 
            offset={10} 
          />
        </Bar>
      </BarChart>
          </ResponsiveContainer>
        </Paper>
    </Box>
    </Grid>
    )}
      {(selectedVague === "Marques") && (
      <Box display="flex"
      justifyContent="center"
      alignItems="center" mt={2}>
                    <Paper 
                      elevation={3}
                      sx={{
                        width: { xs: '100%', sm: '90%', md: 'auto' },
                        maxWidth: { xs: 350, sm: 500, md: isXl ? 800:600 },
                        height: { xs: 380, sm: 400, md: 400 },
                        padding: 2,
                        borderRadius: 3,
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      }}
                    >          
        <div style={{ textAlign: 'center' }}>
                    <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Pannes par marque</span>
                    <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${totPanneMarque}`})</span>
                  </div>
              <ResponsiveContainer width="100%" height={250}>
          <BarChart
            data={chartDataPannesByMark}
            margin={isXs ? {} : { top: 15, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis 
              type="category" 
              dataKey="name" 
              axisLine={false} 
              tickMargin={10} 
              tick={{ fontSize: 16, fontFamily: 'Times New Roman', fontWeight: 'bold' }} 
            />
            <YAxis 
              type="number" 
              axisLine={false} 
              tick={{ fontSize: 14 }} 
            />
            <Tooltip />
            <Bar 
              dataKey="Pannes" 
              fill="#F6CA11" 
              radius={[5, 5, 0, 0]} 
              barSize={40} 
            >
              <LabelList 
                dataKey="Pannes" 
                position="indideEnd" 
                fill="#fff" 
                fontSize={14} 
                offset={10} 
              />
            </Bar>
          </BarChart>
              </ResponsiveContainer>
            </Paper>
        </Box>
      )}
    {(selectedVague === "Puissance motrice") &&(
    <Grid item xs={12} md={6} lg={12} mt={3}>
          <Paper
            elevation={3}
            sx={{
              width: { xs: '100%', sm: '90%', md: 'auto' },
              maxWidth: { xs: 350, sm: 500, md: isXl ? '100%': 1000 },
              height: { xs: 380, sm: 400, md: 400 },
              padding: 2,
              borderRadius: 3,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >     
              <div style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' : '1.5rem' }}>Pannes par puissance</span>
                <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${totPanne}`})</span>
              </div>
        {tracteurPuissance && tracteurPuissance.length > 0 ? (
             <ResponsiveContainer width="100%" height={300}>
             <BarChart
               data={tracteurPuissance} 
               margin={isXs ? {} : { top: 10, right: 30, left: 20, bottom: 5 }}
             >
               <XAxis 
                 type="category" 
                 dataKey="name" 
                 axisLine={false} 
                 tickMargin={isXs ? 2 : 15} 
                 tickLine={false} 
                 interval={0} 
                 tick={{ fontSize: isXs ? 15 : 18, fontFamily: 'Times New Roman', fontWeight: 'bold' }} 
               />
               <YAxis 
                 type="number" 
                 axisLine={false} 
                 tick={{ fontSize: 14 }} 
               />
               <Tooltip />
               <Bar dataKey="Pannes" fill="#F6CA11" radius={[5, 5, 0, 0]} barSize={40}>
                 <LabelList 
                   dataKey="Pannes" 
                   position="insideEnd" 
                   fill="#fff" 
                   fontSize={14} 
                   offset={10} 
                 />
               </Bar>
             </BarChart>
           </ResponsiveContainer>
        ) : (
          <Typography sx={{ textAlign: 'center', padding: 2 }}>Aucune donnée</Typography>
        )}
      </Paper>
      
    </Grid>
    )}
            <Box mt={3}>
              <Autocomplete
                options={anneeOptions}
                getOptionLabel={(option) => option.label}
                onChange={handleAnneeChange}
                renderInput={(params) => <TextField {...params} label="Année" color='success' size='small' />}
                sx={{ width: 150 }}
              />
            </Box>
                <Typography variant="h6"  sx={{ 
                      fontWeight: 'bold', 
                      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
                      textAlign: 'center',
                      marginTop:'15px',
                      mb: { xs: 2, sm: 3 }
                      }}> Nombre de pannes par mois</Typography>
                  <ResponsiveContainer width="100%" sx={{  maxWidth: {
                    xs: '100%',  
                    sm: '100%', 
                    md:'800px'
                  }}} 
                  height={400}> 
               <LineChart data={chartData}>
                 <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                   <Legend />
                   <Line
                     type="monotone"
                     dataKey="Panne"
                    stroke="#F6CA11"
                    activeDot={{ r: 6 }}
                    />
                </LineChart>
  </ResponsiveContainer>
          </TabPanel>
          <TabPanel value="2">
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomCard title="Total des visites" value={`${total_visites}`} color="#ffff" icon={<TourIcon />} svalue={'+100'}/>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomCard title="Tracteurs" value={`${Tracteurs}`} color="#ffff" icon={<TireRepairIcon />} svalue={'+100'}/>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomCard title="Motoculteurs" value={`${Motoculteurs}`} color="#ffff" icon={<RepartitionIcon />} svalue={'+100'}/>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomCard title="Autres" value={`${Autre}`} color="#ffff" icon={<RepartitionIcon />} svalue={'+100'}/>
        </Grid>
        <Grid container spacing={2} mt={3}>
      <Grid item xs={12} md={6} mt={1} lg={12}>
                <Paper
                  elevation={3}
                  sx={{
                    width: { xs: '100%', sm: '90%', md: 'auto' },
                    maxWidth: { xs: 350, sm: 500, md: isXl ? '100%': 1000 },
                    height: { xs: 380, sm: 400, md: 400 },
                    padding: 2,
                    borderRadius: 3,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >          
                  <div style={{textAlign:'center'}}>
                      <span style={{ fontWeight: 'bold', fontSize: isXs ? '1rem' :'1.5rem' }}>Visite par marque</span>
                      <span style={{ marginLeft: '10px', color: '#02834A', fontWeight: 'bold', fontSize: isXs ? '1rem' : '2.5rem', }}>({`${total}`})</span>
                    </div>
          <ResponsiveContainer width="100%" height={300}>
           <BarChart
             data={typeChartData} 
             margin={isXs ? {} : { top: 20, right: 30, left: 20, bottom: 5 }}
           >
             <XAxis 
               type="category" 
               dataKey="label" 
               axisLine={false} 
               tickMargin={isXs ? 2 : 15} 
               tickLine={false} 
               interval={1} 
               tick={{ fontSize: isXs ? 15 : 18, fontFamily: 'Times New Roman', fontWeight: 'bold' }} 
             />
             <YAxis 
               type="number" 
               axisLine={false} 
               tick={{ fontSize: 14 }} 
             />
             <Tooltip />
             <Bar dataKey="visite" fill="#F6CA11" radius={[5, 5, 0, 0]}>
               <LabelList 
                 dataKey="visite" 
                 position="insideEnd" 
                 fill="#fff" 
                 fontSize={14} 
                 offset={10} 
               />
             </Bar>
           </BarChart>
         </ResponsiveContainer>
        </Paper>
    </Grid>
      </Grid>
      <Container fluid className="mt-3">
              <Box
                sx={{
                  width: '100%', // Prend 100% de la largeur
                  height: '400px',
                  overflow: 'auto',
                  margin: '0 auto', // Centrage
                  maxWidth: {
                    xs: '325px',  
                    sm: '100%',   // 100% pour sm et au-delà
                  },
                }}
              >
                <TableComponent
                  data={visitesData}
                  columns={columns}
                  sx={{
                    '& .MuiTableCell-root': {
                      padding: '4px',
                      fontSize: { xs: '0.8rem', sm: '1rem' }, // Ajustement de la taille de police
                    },
                  }}
                />
              </Box>
            </Container>
      </Grid>
      </TabPanel> 
          <TabPanel value="3">
          <Grid item xs={12} md={4} mt={3}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold'}}>
                    Mécaniciens par ville 
                  </Typography>
                  <Paper elevation={4} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 'auto', width: 400, position: 'relative' }}>
                    <MapChart2 />
                  </Paper>
                </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
  

};

export default RoleCheck(['Mecanicien'])(DashboardMecanicien);