import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { resetPassword } from '../api/auth_client';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(token, password);
      setMessage('Mot de passe réinitialisé avec succès');
      navigate('/'); 
    } catch (error) {
      setMessage('Erreur : veuillez réessayer.');
    }
  };

  return (
    <div className='login template d-flex justify-content-center align-items-center vh-100 bg-success'>
      <div className='form_container p-5 rounded bg-white'>
        <h3 className='text-center'>Réinitialiser le mot de passe</h3>
        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label>Nouveau mot de passe</label>
            <input 
              type='password' 
              className='form-control' 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          <button type='submit' className='btn btn-success w-100'>Confirmer</button>
        </form>
        {message && <p className="mt-3 text-center">{message}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;
